<template>
  <v-dialog
      v-model="dialogShow"
      :max-width="myConfirmCloseOptions.width"
      :style="{ zIndex: myConfirmCloseOptions.zIndex }"
      @keydown.esc="dialogShow=false"
  >
    <v-card>
      <v-toolbar dark :color="myConfirmCloseOptions.color" dense flat>
        <v-toolbar-title class="text-body-2 font-weight-bold grey--text">
          {{ myConfirmCloseOptions.title }}
        </v-toolbar-title>
      </v-toolbar>
<!--      <v-container>-->
<!--        <v-card-subtitle align="center" class="text-h6">{{ myConfirmCloseOptions.message1 }}</v-card-subtitle>-->
<!--        <v-card-subtitle align="center" class="text-h6">{{ myConfirmCloseOptions.message2 }}</v-card-subtitle>-->
<!--      </v-container>-->
        <p align="center" class="mt-2">
          {{myConfirmCloseOptions.message1}}<br>{{myConfirmCloseOptions.message2}}
        </p>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" outlined text @click="dialogShow=false">Cancel</v-btn>
        <v-btn color="warning darken-1" outlined text @click="closeConfirmed" ><v-icon small>mdi-close</v-icon> Close</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "CloseDialog",
  data() {
    return {
      dialogShow: false,
      myConfirmCloseOptions: {
        title: 'Konfirmasi Close',
        message1: 'Sudah ada Modifikasi, Lanjutkan Cancel?',
        message2: '',
        color: "grey lighten-3",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    }
  },

  props: [
  ],
  computed: {
  },
  methods: {
    closeConfirmed: function(){
      // this.myConfirmDelete = false
      this.$emit('eventFromCloseConfirm', "OKE")
    },
    showDialog(message1, message2) {
      // console.log("from parent " + message)
      this.myConfirmCloseOptions.message1 = message1!==undefined?message1:''
      this.myConfirmCloseOptions.message2 = message2!==undefined?message2:''
      this.dialogShow = !this.dialogShow
    },
    setDialogState(value){
      console.log(value)
      this.dialogShow =false
    }


  }

}
</script>

<style scoped>

</style>