export default class FCustomerGroup {
  constructor(
    id,
    kode1,
    description,
    avatarImage,
    fdivisionBean,
    statusActive=true,

    ftPriceAlthBean,

    autoOngkir =true,
    lockDiscNota =false,

    custOnline =false,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.avatarImage = avatarImage;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.ftPriceAlthBean = ftPriceAlthBean;

    this.autoOngkir = autoOngkir;
    this.lockDiscNota = lockDiscNota;

    this.custOnline = custOnline;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
