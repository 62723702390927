<template>
  <v-card class="mt-2 ml-2 mr-2">
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabCustomerGroupClick">
        CUST TYPE
      </v-tab>
      <v-tab class="justify-start" @click="tabDistributionChannelClick">
        CHANNEL
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FCustomerGroupTable ref="refTabCustomerGroupClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FDistributionChannelTable ref="refTabDistributionChannelClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>

    </v-tabs>

  </v-card>
</template>

<script>
import FCustomerGroupTable from "../../components/mitra/category/FCustomerGroupTable";
import FDistributionChannelTable from "../../components/mitra/category/FDistributionChannelTable";

export default {
  components: { FCustomerGroupTable,  FDistributionChannelTable},
  data() {
    return {
      firstTabTitle: 'KATEGORI',
      firstTabIcon: 'mdi-file-tree'
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabCustomerGroupClick(){
      try {
        this.$refs.refTabCustomerGroupClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },
    tabDistributionChannelClick(){
      try {
        this.$refs.refTabDistributionChannelClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },


  }
}
</script>

<style scoped>
</style>