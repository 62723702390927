import {format, parseISO} from "date-fns";

function formatDate (date){
    if (!date) return null
    const [year, month, day] = date.split('-')
    return `${month}/${day}/${year}`
}

function dateFormattedDatefnsWithPattern (value, pattern) {
    if (!value) return null
    if (!pattern) pattern = 'dd-MMM-yyyy'
    return value ? format(parseISO(value), pattern) : ''
}

function dateFormattedDatefns (value) {
  return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
}

function dateFormattedDatefnsShort(value) {
  return value ? format(parseISO(value), 'dd-MM-yy') : ''
}

function dateFormattedDatefnsWithTime (value) {
  return value ? format(parseISO(value), 'dd-MMM-yy HH:mm') : ''
}

function dateFormattedDatefnsWithTimeShort (value) {
  return value ? format(parseISO(value), 'dd-MMM HH:mm') : ''
}


export  {formatDate, dateFormattedDatefnsWithPattern, dateFormattedDatefns, dateFormattedDatefnsShort, dateFormattedDatefnsWithTime, dateFormattedDatefnsWithTimeShort}